import { template as template_e775d61e57534f0caeb1a39bb0fa2364 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e775d61e57534f0caeb1a39bb0fa2364(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
