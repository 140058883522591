import { template as template_d41325c3c7f544f09ac29e682df917f4 } from "@ember/template-compiler";
const WelcomeHeader = template_d41325c3c7f544f09ac29e682df917f4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
