import { template as template_f02d4626bef943ad9494368b1b348f81 } from "@ember/template-compiler";
const FKText = template_f02d4626bef943ad9494368b1b348f81(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
