import { template as template_6386abbc0a5d4b02b6c83210ce41eea7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6386abbc0a5d4b02b6c83210ce41eea7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
